import { AuthenticationResult } from '@feathersjs/authentication';
import feathers from '@feathersjs/client';
import { HookContext, Params } from '@feathersjs/feathers';
import restClient from '@feathersjs/rest-client';
import { ContractToShorten } from '@tymbe/legislatives/ContractToShorten';
import { CzechSalaryType } from '@tymbe/legislatives/czechia/CzechSalaryType';
import { AddressData } from '@tymbe/schema/address.interface';
import { ApplicationData } from '@tymbe/schema/application.interface';
import { ValidateResp } from '@tymbe/schema/claim-shift-precheck.interface';
import {
  ApplicationState,
  AttendanceResolution,
  AuthManagementActionTypes,
  ContactType,
  ContractDocumentTypes,
  PaymentStatusType,
  Roles,
  SalaryLimitType,
  DocumentType,
} from '@tymbe/schema/enums';
import { PaymentAuthorizedData } from '@tymbe/schema/payment-authorized.interface';
import { PayoutInfoData } from '@tymbe/schema/payout-info.interface';
import { PersonSalaryLimitData } from '@tymbe/schema/person-salary-limit.interface';
import { SalaryCalculationData } from '@tymbe/schema/salary-calculation.interface';
import { VacationData, VacationSlot } from '@tymbe/schema/vacation.interface';
import { getSalaryLimitMonth } from '@tymbe/utils/person-salary-limit.utils';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import moment from 'moment-timezone';
import ReactGA from 'react-ga4';
import { IntlShape } from 'react-intl';

import getQueryArrayLength from './hook/getQueryArrayLength';
import {
  getResponseArray,
  ServiceTypes,
} from './NewApi';
import {
  applicationToRing,
  attendanceToCredit,
  calculateWorkTime,
  companyDataToCompanyRef,
  genderToGender,
  loginToRegistration,
  loginToTymber,
  mapPersonDocumentToTymberDocument,
  perkDataToPerk,
  personDocumentDataToRingDocument,
  personDocumentToDocument,
  shiftToRing,
  surveyDataToSurvey,
  utilityToTymberUtility,
} from './transformations';
import {
  ApiError,
  ClaimedStates,
  CompanyRef,
  Credit,
  FinishedStates,
  Login,
  RegistrationDataRequest,
  Ring,
  RingDocument,
  RingDocumentSigned,
  RingShiftClaimPrecheck,
  SaveTymberDocumentDataRequest,
  Survey,
  SurveyAnswer,
  TestJson,
  Tymber,
  TymberDocument,
  TymberDocumentPart,
  TymberUtility,
} from '../../types/Tymbe';

class Api {
  public feathers = feathers<ServiceTypes>();
  public intl?: IntlShape;
  /**
   * constructor
   */
  constructor() {
    // Custom axios error handling middleware (interceptor)
    axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        switch (error?.request?.responseType) {
          case 'blob':
          case 'arraybuffer':
            try {
              error.response.data = JSON.parse((new TextDecoder()).decode(error.response.data))
            } catch {}
            /* if (error.response.data instanceof Blob) {
              error.response.data = await (error.response.data as Blob).arrayBuffer();
            }
            // Convert ArrayBuffer error response to JSON manually
            const json = String.fromCharCode.apply(null, new Uint8Array(error.response.data) as any);
            // Parse JSON string to JSON object so that we match the default responseType behaviour
            jsonError = JSON.parse(json); */
            break;

          // Default responseType (which is JSON), navigate to the data in the response
        }

        // In case of code 500, response contains data: {} and for some reason it's presence
        // changes behaviour of feathers internal error handling. With data: {} the internal error handling fails
        if (!error?.response?.data) {
          throw new ApiError({
            status: 500,
            path: error.config.url,
            method: error.config.method,
            message: this.intl!.formatMessage({
              defaultMessage: 'Neznámá chyba',
              description: 'Text chyby',
            }),
            id: 'GENERIC',
          });
        }

        throw new ApiError({
          status: error.response.data.code,
          path: error.config.url,
          method: error.config.method,
          message: error.response.data.message,
          id: error.response.data.name,
          data: error.response.data.data,
        });
      },
    );

    const rest = restClient(import.meta.env.CLIENT_API_URL);
    this.feathers.configure(rest.axios(axios));
    this.feathers.configure(feathers.authentication({
      storage: window.localStorage,
      storageKey: 'feathers-react-jwt',
    }));

    this.feathers.hooks({
      before: [
        (hook: HookContext) => {
          if (!hook || !Object.hasOwn(hook, 'arguments')) return hook;
          if (!Array.isArray(hook.arguments)) return hook;
          if (hook.arguments.length === 0) return hook;
          // Check if any of the arguments contains the array modifiers
          if (!['$in', '$nin', '$and', '$or', '$select'].some((el) => JSON.stringify(hook.arguments).includes(el))) {
            return hook;
          }

          const maxQueryLimit = import.meta.env.CLIENT_FEATHERS_MAX_ARRAY_LIMIT || 21;

          hook.arguments.forEach((args) => {
            if (!args || !Object.hasOwn(args, 'query')) return;
            const { query } = args;
            const queryErrs: string[] = getQueryArrayLength(query, maxQueryLimit);
            if (queryErrs.length > 0) {
              throw new Error(`Json array may include up to ${maxQueryLimit} elements, not more! Affected query properties: [${queryErrs.join(',')}]`);
            }
          });
          return hook;
        },
      ],
      after: [],
      error: [],
    });

    this.getUser();
  }

  public getUser = async () => {
    try {
      const res = await this.feathers.reAuthenticate();
      await this.allowOnlyTymbers(res.user);

      const { smartsupp } = (window as any);
      if (smartsupp && !(window as any).smartsupp_user_init && import.meta.env.MODE !== 'test') {
        (window as any).smartsupp_user_init = true;
        smartsupp('name', `${res.user.person.first_name}${res.user.person.middle_name ? ` ${res.user.person.middle_name}` : ''} ${res.user.person.last_name}`)
        smartsupp('email', res.user.username);
        setTimeout(() => {
          smartsupp('chat:show');
        }, 1000);
      }

      return res.user;
    } catch (e) {
      return null;
    }
  };

  private async getUserOrThrow() {
    const user = await this.getUser();
    if (!user) {
      throw new Error('User not found');
    }
    return user;
  }

  public getBucketFile = async (path: string) => {
    const data = this.feathers.service('bucket').get(
      path,
      {
        query: {
          $download: true,
        },
        connection: {
          responseType: 'blob',
        },
      }
    );
    const file = await data.then(URL.createObjectURL);

    return this.createAxiosResponse(file);
  };

  /**
   * Sends login request with provided username and password
   * @param username
   * @param password
   */
  public login = async (username: string, password: string): Promise<AxiosResponse<Login>> => {
    const res = this.feathers.authenticate({ strategy: 'local', username, password });
    const result = await res;
    const personData = await this.feathers.service('person-data').get(result.user.person.id);
    await this.allowOnlyTymbers(result.user);

    ReactGA.event({
      category: 'login',
      action: 'login',
      label: 'Successfully logged in', // optional
    });

    return this.createAxiosResponse({
      id: result.user.id,
      token: result.accessToken,
      email: result.user.username,
      gender: genderToGender(personData.gender),
      birthDate: personData.birthdate || '',
    });
  };

  private async allowOnlyTymbers(user: AuthenticationResult['user']) {
    if (!user.role?.some((r => r.slug === 'tymber'))) {
      await this.feathers.logout();
      throw new ApiError({
        status: 404,
        path: '/auth',
        method: 'POST',
        message: 'Těmihle údaji se nepřihlásíš, zkus jiné.',
        id: 'GENERIC',
      });
    }
  }

  /**
   * logout
   * @param _token
   */
  public logout = (_token?: string) => {
    this.feathers.logout();
    const smartsupp = (window as any).smartsupp;
    smartsupp('chat:hide');
    delete (window as any).smartsupp_user_init;
  }

  /**
   * forgotPassword
   * @param email
   */
  public forgotPassword = (email: string) => {
    this.feathers
      .service('auth-management')
      .create({ action: AuthManagementActionTypes.SEND_RESET_PASSWORD, email });

    return this.createAxiosResponse({});
  };

  /**
   * resetPassword
   * @param password
   * @param token
   */
  public resetPassword = async (password: string, token?: string | null) => {
    const res = await this.feathers.service('auth-management').create({
      action: AuthManagementActionTypes.RESET_PASSWORD,
      password,
      token,
    });
    return this.createAxiosResponse({ data: res });
  };

  /**
   * changePassword
   * @param oldPassword
   * @param newPassword
   * @param _token
   */
  public changePassword = async (oldPassword: string, newPassword: string, _token?: string) => {
    const user = await this.getUserOrThrow();

    await this.feathers
      .service('authentication')
      .create(
        { strategy: 'local', username: user.username, password: oldPassword },
      );
    this.feathers
      .service('login')
      .patch(user.id, { password: newPassword });

    return this.createAxiosResponse({});
  };

  /**
   * getRegistration
   * @param regId
   */
  public getRegistration = async (regId: string) => {
    const res = await this.feathers
      .service('login')
      .find({
        query: {
          id: regId,
          $eager: '[person.personData]',
        },
      });

    const login = getResponseArray(res)[0];

    return this.createAxiosResponse(loginToRegistration(login));
  };

  /**
   * saveRegistration
   * @param _regId
   * @param _data
   */
  public saveRegistration = async (_regId: string, _data: RegistrationDataRequest) => {
    throw new ApiError({
      status: 501,
      path: '/registration/:id',
      method: 'POST',
      message: 'Tohle snad někdy dokončíme. Zatím se zabav na jiné části systému.',
      id: 'GENERIC'
    });
    // TODO: Implement
    /*
    const res = await this.feathers
      .service('login')
      .patch(
        regId,
        {

        },
        {
        query: {
          $eager: '[person.personData]',
        },
      });
    return this.createAxiosResponse(loginToRegistration(res));
    */
  };

  /**
   * getTymber
   */
  public getTymber = async () => {
    const user = await this.getUserOrThrow();

    const login = await this.feathers.service('login').get(user.id,
      {
        query: {
          $eager: '[person.[accountState, personDocument(globalDocuments, validFromNowOn).documentType, contact, personUtility.[utility,company], personData.[permanentAddress, contactAddress]]]',
        },
      },
    );

    const resInvitations = await this.feathers.service('application').find({
      query: {
        person_id: user.person_id,
        $limit: -1,
        $eager: '[shift]',
        $joinRelation: '[shift]',
        invitation: true,
        'application.state': {
          $null: true,
        },
        $or: [{'shift.end_time': { $gt: moment().format() }},{ 'application.created_at': { $gt: moment().subtract(24, 'hours').format() }}],
      }
    });

    const resClaimed = await this.feathers.service('application').find({
      query: {
        person_id: user.person_id,
        $limit: -1,
        $eager: '[shift]',
        $joinRelation: '[shift]',
        state: ApplicationState.CONFIRMED,
        'shift.end_time': { $gt: moment().format() },
      },
    });

    const invitations = getResponseArray(resInvitations).length;
    const claimed = getResponseArray(resClaimed).length;

    const tymber = loginToTymber(login);
    return this.createAxiosResponse({ ...tymber, invitations, claimed });
  };

  /**
   * saveTymber
   * @param data
   * @param password
   * @param config
   * @param _token
   */
  public saveTymber = async (
    data: Partial<Tymber>,
    password: string,
    config?: AxiosRequestConfig,
    _token?: string,
  ) => {
    const user = await this.getUserOrThrow();

    if (password) {
      await this.feathers
        .service('authentication')
        .create(
          { strategy: 'local', username: user.username, password },
        );
    }

    const { foreigner_origin, ...personData } = await this.feathers.service('person-data').get(user.person_id, {
      query: {
        $eager: '[contactAddress, permanentAddress]',
      },
      connection: {
        config,
      },
    });

    let contactAddress: AddressData | undefined;

    if (data.contactAddress) {
      contactAddress = {
        ...personData.contactAddress,
        addressline1: data.contactAddress?.street.join(' ') || personData.contactAddress?.addressline1,
        zip: data.contactAddress?.zip || personData.contactAddress?.zip,
        locality: data.contactAddress?.city || personData.contactAddress?.locality,
        country: data.contactAddress?.country || personData.contactAddress?.country,
      } as AddressData;
    }

    await this.feathers.service('person-data').patch(
      user.person_id,
      {
        ...personData,
        birthdate: undefined,
        family_member_nationality: undefined,
        contactAddress,
        bank_account: (data.bankAccountNumber && data.bankNumber) ? `${data.bankAccountNumber}/${data.bankNumber}` : undefined,
        payment_note: data.paymentNote,
        outstanding_liabilities: undefined,
        job_evaluation: undefined,
        payment_balance: undefined,
        credit_balance: undefined,
        deleted_at: undefined,
        email_notification_allowed: data.email_notification_allowed ?? undefined,
      },
    );

    const res = await this.feathers.service('person-contact').find({
      query: {
        person_id: user.person_id,
        type: ContactType.MOBILE_PHONE,
      },
    });

    const personContact = getResponseArray(res)[0];

    if (!personContact) {
      await this.feathers.service('person-contact').create({
        person_id: user.person_id,
        type: ContactType.MOBILE_PHONE,
        value: data.phone,
      });
    } else {
      await this.feathers.service('person-contact').patch(personContact.id, { value: data.phone });
    }

    return this.getTymber();
  };

  public getPersonSalaryLimit = async (): Promise<AxiosResponse<PersonSalaryLimitData>> => {
    const { person_id } = await this.getUserOrThrow();
    const res = await this.feathers.service('person-salary-limit').get(person_id);
    return this.createAxiosResponse(res);
  };

  public savePersonSalaryLimit = async (limit: SalaryLimitType): Promise<AxiosResponse<PersonSalaryLimitData>> => {
    const { person_id } = await this.getUserOrThrow();
    const data: Omit<PersonSalaryLimitData, 'created_at'|'updated_at'|'deleted_at'> = {
      person_id,
      month: getSalaryLimitMonth(),
      limit,
    };
    const res = await this.feathers.service('person-salary-limit').update(`${data.person_id},${data.month}`, data);
    return this.createAxiosResponse(res);
  };

  public getVacations = async (): Promise<AxiosResponse<VacationData[]>> => {
    const res = await this.feathers.service('vacation').find({
      query: {
        $limit: -1,
      },
    });
    const vacations = getResponseArray(res);
    return this.createAxiosResponse(vacations);
  };

  public claimVacation = async (selectedSlot: VacationSlot, vacation: VacationData): Promise<AxiosResponse<ApplicationData>> => {
    const res = await this.feathers.service('vacation-claim').create({
      company_id: vacation.company_id,
      person_document_id: vacation.person_document_id,
      start_time: selectedSlot.start_time,
      end_time: selectedSlot.end_time,
    });
    return this.createAxiosResponse(res);
  };

  public getRings = async (_params?: object, _token?: string): Promise<AxiosResponse<Ring[]>> => {
    const { person_id } = await this.getUserOrThrow();

    const res = await this.feathers.service('shift').find(
      {
        query: {
          $modify: {
            activeContests: true,
            freeShifts: [person_id],
            notBlocked: [person_id],
            fulfilledPerks: [person_id],
          },
          $joinRelation: '[company]',
          $leftJoinRelation: '[branchoffice.[address, parent.address]]',
          $limit: 20,
          $sort: { start_time: 1 },
          $eager: '[position, shiftTemplate, perk, utility, company.address, manShift, branchoffice.[address, parent.address], documentType]',
          ..._params,
        },
      },
    );
    const shifts = getResponseArray(res);

    const data = shifts.map(shiftToRing);
    return this.createAxiosResponse(data);
  };

  public getNumberOfRings = async (_params?: object): Promise<AxiosResponse<number>> => {
    const { person_id } = await this.getUserOrThrow();

    const res = await this.feathers.service('shift').find(
      {
        query: {
          $modify: {
            activeContests: true,
            freeShifts: [person_id],
            notBlocked: [person_id],
            fulfilledPerks: [person_id],
          },
          $joinRelation: '[company]',
          $leftJoinRelation: '[branchoffice.[address, parent.address]]',
          $limit: 0,
          ..._params,
        },
      },
    );
    // Can't change the type of res
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this.createAxiosResponse(res.total);
  };

  /**
   * getInvitations
   * @param params
   * @param _token
   */
  public getInvitations = async (params?: object, _token?: string) => {
    const { person_id } = await this.getUserOrThrow();

    const res = await this.feathers.service('application').find({
      query: {
        $eager: '[attendance, manShift, shift.[branchoffice.[address, parent.address], company.address, manShift, perk, shiftTemplate]]',
        $joinRelation: '[shift.company]',
        invitation: true,
        'application.state': {
          $null: true,
        },
        $or: [{'shift.end_time': { $gt: moment().format() }},{ 'application.created_at': { $gt: moment().subtract(24, 'hours').format() }}],
        $sort: { 'shift.start_time': 1 },
        $limit: 20,
        ...params,
        person_id,
      },
    });

    const invites = getResponseArray(res);

    const invitations = invites.map(applicationToRing);

    return this.createAxiosResponse(invitations);
  };

  public rejectInvitation = async (_ringId: Ring['id'], _token?: string) => {
    const { person_id } = await this.getUserOrThrow();

    const res = await this.feathers.service('application').find({
      query: {
        $joinRelation: 'shift',
        $eager: '[shift, manShift]',
        'shift.id': _ringId,
        state: { $null: true },
        person_id,
      },
    });

    const applications = getResponseArray(res);

    if (applications.length > 1) {
      console.warn('More than one application found, cant reject');
    }

    // TODO: use patch with id instead of null
    const patchResponse = await this.feathers.service('application').patch(
      null,
      { state: ApplicationState.REJECTED },
      {
        query: {
          id: applications[0].id,
          $eager:
            '[attendance, shift.[company.address, branchoffice.[address, parent.address], ' +
            'position, manShift, perk, utility], person]',
          person_id,
        },
      },
    );

    if (!patchResponse[0]?.shift) {
      throw new ApiError({
        status: 404,
        path: `/invitation/${_ringId}`,
        method: 'DELETE',
        message: `Brigádu ${_ringId} nemůžu nikde najít.`,
        id: 'GENERIC'
      });
    }

    return this.createAxiosResponse(shiftToRing(patchResponse[0].shift));
  };

  public getClaimed = async (params?: object, _token?: string) => {
    const { person_id } = await this.getUserOrThrow();

    const res = await this.feathers.service('application').find({
      query: {
        $eager:
          '[attendance, shift.[company.address, branchoffice.[address, parent.address], ' +
          'position, manShift, perk, utility], person]',
        person_id,
        'application.state': ApplicationState.CONFIRMED,
        $joinRelation: '[shift.company]',
        'shift.end_time': { $gte: moment().format() },
        $sort: { 'shift.start_time': 1 },
        $limit: 20,
        ...params,
      },
    });

    const applications = getResponseArray(res);

    const data = applications.map((item): Ring => {
      let ring = shiftToRing(item.shift!);

      ring = {
        ...ring,
        claimed: true,
        credits: {
          hour: item.credits,
          shift: calculateWorkTime(item.shift!.start_time, item.shift!.end_time) * item.credits,
        },
        state: ClaimedStates.CLAIMED,
      };

      return ring;
    });

    return this.createAxiosResponse(data);
  };

  public cancelClaimed = async (ringId: Ring['id'], isLastMinute: boolean, _token?: string,) => {
    const { person_id } = await this.getUserOrThrow();

    const res = await this.feathers.service('application').find({
      query: {
        $joinRelation: 'shift',
        $eager: '[shift, manShift]',
        state: ApplicationState.CONFIRMED,
        'shift.id': ringId,
        person_id,
      },
    });

    const applications = getResponseArray(res);

    if (applications.length > 1) {
      console.warn('More than one application found, cant reject');
    }

    const [application] = applications;

    const patchResponse = await this.feathers.service('application').patch(
      application.id,
      { state: isLastMinute ? ApplicationState.CANCELED_LATE : ApplicationState.CANCELED_EARLY },
      {
        query: {
          $eager:
            '[attendance, shift.[company.address, branchoffice.[address, parent.address], ' +
            'position, manShift, perk, utility], person]',
        },
      },
    );

    if (!patchResponse || !application.shift) {
      throw new ApiError({
        status: 404,
        path: `/claimed/${ringId}`,
        method: 'DELETE',
        message: `Brigádu ${ringId} nemůžu nikde najít.`,
        id: 'GENERIC'
      });
    }

    return this.createAxiosResponse(shiftToRing(application.shift));
  };

  public getFinished = async (params?: object, _token?: string) => {

    const res = await this.feathers.service('application').find({
      query: {
        $eager:
          '[attendance, shift.[company.address, branchoffice.[address, parent.address], ' +
          'position, manShift, perk, utility], person]',
        $joinRelation: '[shift.company]',
        'application.state': {
          $null: false,
        },
        'shift.end_time': { $lte: moment().format() },
        $sort: { 'shift.start_time': -1 },
        $limit: 20,
        ...params,
      },
    });

  const finishedState = (application: ApplicationData) => {
    if (application.attendance === null) {
      switch (application.state) {
        case ApplicationState.CONFIRMED:
          return FinishedStates.AWAITING_CONFIRMATION;
        case ApplicationState.CANCELED_LATE:
          return FinishedStates.CANCELED_LATE
        case ApplicationState.CANCELED_EARLY:
          return FinishedStates.CANCELED_EARLY
        case ApplicationState.SYSTEM_CANCELED:
          return FinishedStates.SYSTEM_CANCELED
          default:
          break;
      }
    }

    const resolution: AttendanceResolution | undefined =
      application?.attendance?.resolution;

    switch (resolution) {
      case AttendanceResolution.UNEXCUSED_ABSENCE:
        return FinishedStates.ABSENT_NOK;
      case AttendanceResolution.EXCUSED_ABSENCE:
        return FinishedStates.ABSENT_OK;
      case AttendanceResolution.ADMIN_EXCUSED_ABSENCE:
        return FinishedStates.ABSENT_OK;
      case AttendanceResolution.OK:
      case AttendanceResolution.BREACH_OF_DISCIPLINE:
        return FinishedStates.COMPLETED;
      default:
        return undefined;
    }
  };

    const applications = getResponseArray(res);

    const data = applications.map((item): Ring => {
      let ring = shiftToRing(item.shift!);
      ring = {
        ...ring,
        claimed: true,
        credits: {
          hour: item.credits,
          shift: calculateWorkTime(item.shift!.start_time, item.shift!.end_time) * item.credits,
        },
        state: ClaimedStates.CLAIMED,
        // TODO: fix this
        // @ts-ignore
        finishedState: finishedState(item),
        duration: item.attendance ? item.attendance.confirmed_time / 60 : ring.duration,
        durationOver: item.attendance ? item.attendance.confirmed_overtime / 60 : ring.durationOver,
        durationCredit: item.attendance ? item.attendance.confirmed_credit_time / 60 : ring.durationCredit,
      };
      return ring;
    });

    return this.createAxiosResponse(data);
  };

  /**
   * getRing
   * @param ringId
   * @param _token
   */
  public getRing = async (ringId: Ring['id'], _token?: string) => {
    const { person_id } = await this.getUserOrThrow();

    const shift = await this.feathers.service('shift').get(ringId, {
      query: {
        $eager: '[position, shiftTemplate, perk, utility, company.address, manShift.application, branchoffice.[address, parent.address], documentType]',
        $findEmployer: true,
      },
    });

    const ring = {
      ...shiftToRing(shift),
      userApplication: shift.manShift?.filter(manshift => manshift?.application?.person_id === person_id).at(0)?.application
    }

    return this.createAxiosResponse(ring);
  };

  /**
   * claimRing
   * @param ringId
   * @param _token
   */
  public claimRing = async (ringId: Ring['id'], _token?: string) => {
    await this.getUserOrThrow();

    let res;
    res = await this.feathers.service('claim-shift').create(
      { shift_id: ringId },
      {
        query: {
          $eager: 'shift.[position, shiftTemplate,' +
            ' perk, company.address, manShift, branchoffice.address]',
        },
      },
    );
    // Check if the ring/shift is an invitation and has incorrectly set state - has to be done through PATCH
    if (res.state === null && res.invitation) {
      const app = await this.feathers.service('application').patch(
        null,
        { state: ApplicationState.CONFIRMED },
        {
          query: {
            id: res.id,
            $eager: 'shift.[position, shiftTemplate,' +
              ' perk, utility, company.address, manShift, branchoffice.address]',
          }
        }
      )
      res = app[0]
    }

    let ring = shiftToRing(res.shift!);

    ring = {
      ...ring,
      claimed: true,
      credits: {
        hour: res.credits,
        shift: calculateWorkTime(res.shift!.start_time, res.shift!.end_time) * res.credits,
      },
      state: ClaimedStates.CLAIMED,

    };
    console.log(`Claiming ring ${ringId} result: `, res);

    return this.createAxiosResponse(ring);
  };

  /**
   * precheckShiftClaim
   * @param ringId
   * @param _token
   */
  public precheckShiftClaim = async (ringId: Ring['id']): Promise<AxiosResponse<RingShiftClaimPrecheck>> => {
    const res: ValidateResp = await this.feathers.service('claim-shift-precheck').create({
      shift_id: ringId,
    });

    return this.createAxiosResponse({
      ...res,
      contracts: res.documentTypes
        .filter((doc) => doc.is_signable)
        .map((c) => ({
          id: c.id,
          signed: personDocumentToDocument(
            ringId,
            res.personDocuments.find((pd) => pd.document_type_id === c.id),
          ),
          template: c.template_id!,
          title: c.name,
          tymbe: false,
          form_definition: c.form_definition as unknown as TestJson,
        })),
    });
  };

  /**
   * getRingDocument
   * @param ringId
   * @param documentId
   * @param _tymbe
   * @param _token
   */
  public getRingDocument = async (
    ringId: Ring['id'],
    documentId: RingDocument['id'],
    _tymbe: boolean,
    _token?: string,
  ) => {
    const { person_id } = await this.getUserOrThrow();

    const shift = await this.feathers.service('shift').get(ringId, {
      query: {
        $eager: '[documentType]',
      },
    });

    const contract = shift.documentType?.find(d => d.id === documentId);

    const res = await this.feathers.service('person-document').find({
      query: {
        person_id: person_id,
        document_type_id: contract?.id,
        valid_to: { $gt: shift.start_time },
      },
    });

    const personDocuments = getResponseArray(res);

    return this.createAxiosResponse([
      {
        id: contract?.id,
        signed: personDocumentToDocument(shift.id, personDocuments?.find(pd => pd.document_type_id === contract?.id)),
        template: contract?.template_id,
        title: contract?.name,
        tymbe: false,
      },
    ]);
  };

  public getTymberDocuments = async (_token?: string, skip?: number) => {
    const { person_id } = await this.getUserOrThrow();

    const res = await this.feathers.service('person-document').find({
      query: {
        $joinRelation: '[documentType]',
        $eager: '[documentType, personDocumentFile]',
        $skip: skip,
        $limit: 3,
        person_id,
        'documentType.is_signable': false
      },
    });

    const data = getResponseArray(res);

    const docs: TymberDocument[] = data.map(mapPersonDocumentToTymberDocument)
    return this.createAxiosResponse(docs);
  };

  /**
   * invalidateTymberDocument
   * @param documentId
   * @param _token
   */
  public invalidateTymberDocument = async (documentId: TymberDocument['id'], _token?: string) => {
    await this.getUserOrThrow();

    // In new API we delete instead of set invalidated flag
    const res = await this.feathers.service('person-document').remove(documentId);
    const [data] = getResponseArray(res);

    return this.createAxiosResponse(mapPersonDocumentToTymberDocument(data));
  };

  /**
   * saveTymberDocument
   * @param data
   * @param config
   * @param _token
   */
  public saveTymberDocument = async (data: SaveTymberDocumentDataRequest, config?: AxiosRequestConfig, _token?: string) => {
    const { person_id } = await this.getUserOrThrow();

    const formData = new FormData();

    if (Array.isArray(data.files)) {
      data.files.forEach((x) => {
        formData.append('files', x);
      });
    } else {
      formData.append('files', data.files);
    }

    if (data.did || data.did === null) formData.append('did', data.did || 'null');
    formData.append('person_id', `${person_id}`);
    formData.append('document_type_id', String(data.document_type_id));
    formData.append('name', 'Dokument');
    formData.append('valid_from', data.from!);
    formData.append('valid_to', data.to!);

    const response = await this.feathers.service('person-document').create(formData as any, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      // use this for axios specific options
      connection: {
        ...config,
      },
      query: {
        $joinRelation: '[documentType]',
        $eager: '[documentType, personDocumentFile]',
        person_id: person_id,
        'documentType.is_signable': false,
      },
    });
    return this.createAxiosResponse(mapPersonDocumentToTymberDocument(response));
  };

  public getDocumentTypes = async (type?: DocumentType) => {
    const res = await this.feathers.service('document-type').find({
      query: {
        is_signable: false,
        $limit: -1,
        type,
      },
    });
    return this.createAxiosResponse(getResponseArray(res));
  };

  public getTymberDocumentFile = async (id: TymberDocumentPart['id'], _token?: string) => {
    const data = await this.feathers.service('bucket').get(
      id,
      {
        query: {
          $download: true,
        },
        connection: {
          responseType: 'blob',
        },
      },
    );

    return this.createAxiosResponse(data);
  };

  public getPayoutStatus = async () => {
    const res = await this.feathers.service('payment-status').find(
      {
        query: {
          status_type: PaymentStatusType.PAYOUT_STATUS,
        },
      },
    );
    return this.createAxiosResponse(getResponseArray(res));
  };

  public getUnpaidWages = async () => {
    const { person_id } = await this.getUserOrThrow();

    const res = await this.feathers.service('payment-authorized').find(
      {
        query: {
          'payment_authorized.person_id': person_id,
          $leftJoinRelation: '[paymentRequest.paymentTransaction]',
          $eager: '[company, paymentRequest]',
          'paymentRequest.id[$null]': false,
          'paymentRequest:paymentTransaction.id[$null]': true,
          $limit: 10,
        },
      },
    );
    return this.createAxiosResponse(getResponseArray(res));
  };

  public getTymberUtilities = async (_token?: string) => {
    const { person_id } = await this.getUserOrThrow();
    const res = await this.feathers.service('person-utility').find({
      query: { person_id, $eager: '[company, utility]' },
    });
    return this.createAxiosResponse(getResponseArray(res).map(utilityToTymberUtility) as TymberUtility[]);
  };

  /**
   * signRingDocument
   * @param ringId
   * @param documentId
   * @param tymbe
   * @param signature
   * @param _token
   */
  public signRingDocument =
    async (
      ringId: Ring['id'],
      documentId: RingDocument['id'],
      tymbe: boolean,
      signature: string,
      passed?: string,
    ) => {
      const { person_id } = await this.getUserOrThrow();

      const documentType = await this.feathers.service('document-type').get(documentId);
      if (!documentType.template_id) {
        throw new ApiError({
          status: 404,
          path: `/ring/${ringId}/document/${documentId}/sign`,
          message: `Dokument ${documentId} nemůžu najít. Jestli by tady být měl, napiš nám na MSG.`,
          method: 'POST',
          id: 'GENERIC',
        });
      }

      const shift = await this.feathers.service('shift').get(ringId);

      const signedDocument = await this.feathers.service('person-document')
        .create({
          ...{ shift_id: ringId } as any, // required by hook
          signature,
          person_id,
          document_type_id: documentId,
          valid_from: moment().toISOString(), // will be overriden
          valid_to: moment.tz(shift.end_time, 'europe/prague').endOf('month').toISOString(), // will be overriden
          passed,
        });

      return this.createAxiosResponse({
        id: documentId,
        signed: {
          id: signedDocument.id,
          documentId,
          ringId,
          time: signedDocument.created_at,
          tymbe,
        },
        template: documentType.template_id,
        title: documentType.name,
        tymbe,
      });
    };

  public shortenDocument = async (ringId: Ring['id'], shorten: ContractToShorten) => {
    const res = await this.feathers.service('person-document').patch(shorten.id, {
      ...{ shift_id: ringId } as any, // required by hook
      valid_to: shorten.endsOn, // will be overriden
    });
    return this.createAxiosResponse(res);
  };

  /**
   * getSignedDocuments
   * @param params
   * @param _token
   */
  // _token unused but kept to not break anything
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public getSignedDocuments = async (params?: { month?: number, year?: number, main?: boolean; }, _token?: string) => {
    const user = await this.getUserOrThrow();
    const { person_id } = user;

    const query: Params = {
      $eager: '[documentType]',
      $joinRelation: '[documentType, personDocumentFile]',
      'documentType.is_signable': true,
      person_id,
      $limit: -1,
    };

    if (params) {
      query['person_document.created_at'] = {
        $gte: moment(`${params.year}${params.month}`, 'YYYYM').tz('europe/prague').startOf('month').toISOString(),
        $lte: moment(`${params.year}${params.month}`, 'YYYYM').tz('europe/prague').endOf('month').toISOString(),
      };
      query['documentType.is_signable'] = true;
      query['documentType.type'] = params.main
        ? { $in: ContractDocumentTypes }
        : { $nin: ContractDocumentTypes };
      if (user.role[0].slug === Roles.TYMBER) {
        query['person_document.valid_to'] = params.main
          ? { $gte: moment().tz('europe/prague').startOf('month').toISOString() }
          : { };
      }
    }
    const docsData = await this.feathers.service('person-document').find({
      query,
    });
    const docs = getResponseArray(docsData).map(personDocumentDataToRingDocument);

    return this.createAxiosResponse(docs);
  };

  /**
   * getSignedDocumentFile
   * @param signedId
   * @param _tymbe
   * @param _config
   * @param _token
   */
  public getSignedDocumentFile =
    async (signedId: RingDocumentSigned['id'], _tymbe: boolean, _config?: AxiosRequestConfig, _token?: string) => {
      const res = await this.feathers.service('person-document').get(signedId, { query: { $eager: '[personDocumentFile]' } });

      const file = res.personDocumentFile?.[0]?.file;
      if (!file) {
        throw new ApiError({
          status: 404,
          path: `/ring/document/signed/${signedId}/file`,
          message: `Dokument ${signedId} nemůžu najít. Jestli by tady být měl, napiš nám na MSG.`,
          method: 'GET',
          id: 'GENERIC',
        });
      }

      const uploadedDocument = await this.feathers.service('bucket').get(file, {
        query: {
          $download: true,
        },
        connection: {
          responseType: 'arraybuffer',
        },
      });

      return this.createAxiosResponse(uploadedDocument);
    };

  /**
   * getDocumentPreview
   * @param ringId
   * @param documentId
   * @param _tymbe
   * @param _config
   * @param _token
   */
  public getDocumentPreview =
    async (
      ringId: Ring['id'],
      documentId: RingDocument['id'],
      _tymbe: boolean,
      _config?: AxiosRequestConfig,
      _token?: string,
    ) => {
      const user = await this.getUserOrThrow();

      const res = await this.feathers.service('document-type').get(documentId);

      if (!res.template_id) {
        console.error('Missing template id');
        // return;
      }
      const template = await this.feathers.service('document-template').patch(
        res.template_id,
        { shift_id: ringId, document_type_id: documentId },
        {
          user,
          query: {
            $download: true,
          },
          connection: {
            responseType: 'arraybuffer',
          },
        },
      );

      return this.createAxiosResponse(template);
    };

  public getWageDetail = async (date_time: string, company_id: number, salaryType: CzechSalaryType) => {
    const { person_id } = await this.getUserOrThrow();
    const toDate = moment.tz(date_time, 'europe/prague').endOf('month').toISOString();

    const resSalaryCalc: SalaryCalculationData = await this.feathers.service('salary-calculation').get(person_id, {
      query: {
        companyIds: [company_id],
        from: date_time,
        to: toDate,
        salaryType,
      },
    });

    return this.createAxiosResponse(resSalaryCalc);
  };

  /**
   * getPayrolls
   * @param params
   * @param _token
   */
  public getPayrolls = async (params: { month: number, year: number; contractTypes: string[] }, _token?: string) => {
    const { person_id} = await this.getUserOrThrow();

    const { month, year, contractTypes } = params;
    const res = await this.feathers.service('company').find({
      query: {
        $modify: {
          personWorkedInAt: [year, month, person_id, ...contractTypes]
        }
      }
    });

    return this.createAxiosResponse(getResponseArray(res).map(companyDataToCompanyRef));
  };

  /**
   * getPayrollPdf
   * @param companyId
   * @param year
   * @param month
   * @param _oz
   * @param _token
   */
  public getPayrollPdf = async (companyId: CompanyRef['id'], year: number, month: number, _oz: number = 0, _token?: string) => {
    await this.getUserOrThrow();

    const data = await this.feathers.service('payroll').create({
      year: year,
      month: month,
      employer_id: companyId,
    },{
      query: {
        $download: true,
      },
      connection: {
        responseType: 'arraybuffer',
      },
    });

    return this.createAxiosResponse(data);
  }

  /**
   * TODO
   * getDocument
   * @param documentId
   * @param _tymbe
   * @param _token
   */
  public getDocument = async (documentId: number, _tymbe: boolean, _token?: string) => {
    const document = await this.feathers
      .service('person-document')
      .get(documentId);

    return this.createAxiosResponse(personDocumentDataToRingDocument(document));
  };

  /**
   * TODO
   * signDocument
   * @param documentId
   * @param tymbe
   * @param signature
   * @param token
   */
  public signDocument = (documentId: RingDocument['id'], tymbe: boolean, signature: string, token?: string) =>
    this.post<{ signature: string; }, RingDocument>(`/document/${documentId}/sign`, { signature }, {
      params: { tymbe },
      headers: this.authHeader(token),
    });

  public getWages = async (): Promise<AxiosResponse<PayoutInfoData[]>> => {
    const { person_id } = await this.getUserOrThrow();

    const infos: PayoutInfoData[] = await this.feathers.service('payout-info').find({
      query: { person_id, with_company: true },
    });

    return this.createAxiosResponse(infos);
  };

  public payoutWage = async (date_time: string, company_id: number, salary_type: CzechSalaryType, password: string) => {
    await this.getUserOrThrow();

    await this.feathers.service('auth-management').create({
      action: 'verifyPassword',
      password,
    });

    await this.feathers.service('payment-request').create(
      { paymentAuthorized: { date_time, company_id, salary_type } as PaymentAuthorizedData },
      { query: { $eager: 'paymentAuthorized' } },
    );
  };

  /**
   * getCredits
   * @param _token
   * @param _config
   */
  public getCredits = async (_token?: string, _config?: AxiosRequestConfig): Promise<AxiosResponse<Credit[]>> => {
    const { person_id } = await this.getUserOrThrow();

    const unclaimedCredits = await this.feathers.service('attendance').find(
      {
        query: {
          $limit: -1,
          $eager: '[application.[shift.[branchoffice, company.address]]]',
          $modify: 'unclaimedCredits',
          'application.person_id': person_id,
        },
      });

    const processingCredits = await this.feathers.service('credit-transaction').find(
      {
        query: {
          $leftJoinRelation: '[paymentRequest.paymentTransaction]',
          'paymentRequest.id[$null]': false,
          'paymentRequest:paymentTransaction.id[$null]': true,
          'credit_transaction.person_id': person_id,
          $modify: 'sum',
        }
      });

    const personData = await this.feathers.service('person-data').get(person_id);
    const creditBalance: Credit = {
      id: 0,
      credits: Number(personData.credit_balance) || 0,
      processing: false,
      company: { id: 0, oz: null, logo: null, name: 'Zůstatek' },
      hourPayment: 0,
      overTime: 0,
      pollId: 0,
      workedHours: 0,
      time: { start: '', end: '' },
    };

    const processingCreditsData: Credit[] = getResponseArray(processingCredits).map(({amount}): Credit => ({
      id: 0,
      credits: Math.abs(Number(amount) || 0),
      processing: true,
      company: { id: 0, oz: null, logo: null, name: 'Výplata' },
      hourPayment: 0,
      overTime: 0,
      pollId: 0,
      workedHours: 0,
      time: { start: '', end: '' },
    }));

    const data = getResponseArray(unclaimedCredits).map(attendanceToCredit).concat(processingCreditsData);

    if (creditBalance.credits) {
      data.push(creditBalance);
    }

    return this.createAxiosResponse(data);
  };

  /**
   * payoutCredits
   * @param creditsId
   * @param survey
   * @param password
   * @param _token
   * @param _config
   */
  public payoutCredits = async (
    creditsId: Credit['id'],
    survey: SurveyAnswer,
    password: string,
    _token?: string,
    _config?: AxiosRequestConfig,
  ) => {
    const { person_id } = await this.getUserOrThrow();

    await this.feathers.service('auth-management').create({
      action: 'verifyPassword',
      password,
    });

    if (creditsId === 0) {
      await this.feathers.service('credit-transaction').create({
        person_id,
      });
      return;
    }

    const answers = Object.entries(survey.answers).map(([question_id, answer]) => {
      return {
        attendance_id: creditsId,
        survey_id: survey.id,
        survey_question_id: Number(question_id),
        survey_question_option_id: Number(answer) ? Number(answer) : null,
        other: !Number(answer) ? String(answer) : null,
        person_id,
      };
    });

    await this.feathers.service('survey-answer').create(answers);
  };

  // /**
  //  * TODO
  //  * getLiabilities
  //  * @param _token
  //  * @param _config
  //  */
  // public getLiabilities = async (_token?: string, _config?: AxiosRequestConfig) => {
  //   const user = await this.getUser();
  //   if (!user) {
  //     throw new Error(`User not found`);
  //   }

  //   const res = await this.feathers.service('person-liability').find({
  //     query: {
  //       $eager: '[attendance.application.shift.company]',
  //       amount: { $gt: 0 },
  //     },
  //   });
  //   const liabilities = getResponseArray(res).map(personLiabilityToLiability);

  //   return this.createAxiosResponse(liabilities);
  // };

    /**
   * TODO
   * getLiabilities
   * @param _token
   * @param _config
   */
    public getLiabilities = async (_token?: string, _config?: AxiosRequestConfig) => {
      await this.getUserOrThrow();

      const res = await this.feathers.service('person-data').find();
      return this.createAxiosResponse(res);
    };

  /**
   * TODO
   * payLiabilities
   * @param password
   * @param _token
   * @param _config
   */
  public payLiabilities = async (password: string, _token?: string, _config?: AxiosRequestConfig) => {
    await this.getUserOrThrow();

    await this.feathers.service('auth-management').create({
      action: 'verifyPassword',
      password,
    });

    return this.createAxiosResponse({});
  };

  /**
   * TODO - prepare backend
   * getSurvey
   * @param surveyId
   * @param _token
   * @param _config
   */
  public getSurvey = async (
    surveyId: Survey['id'],
    _token?: string,
    _config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<Survey>> => {
    await this.getUserOrThrow();

    const data = await this.feathers.service('survey').get(surveyId, { query: { $eager: '[questions.options]' } });

    return this.createAxiosResponse(surveyDataToSurvey(data));
  };

  /**
   * getPerks
   */
  public getPerks = async () => {
    const res = await this.feathers.service('perk').find({ query: { $limit: -1 } });
    const perks = getResponseArray(res).map(perkDataToPerk);

    return this.createAxiosResponse(perks);
  };

  private createAxiosResponse = <D>(data: D, status: number = 200): AxiosResponse<D> => ({
    data,
    status,
    statusText: 'OK',
    headers: {},
    config: {},
  });

  /**
   * authHeader
   * @param token
   */
  private authHeader = (token?: string) => {
    return token ? { Authorization: `Bearer ${token}` } : undefined;
  };

  private logError = async (error: AxiosError<any>) => {
    if (error?.config?.responseType === 'blob') {
      const maybeJson = await error?.response?.data?.text?.();
      console.log('handling blob', maybeJson);
      try {
        error.response!.data = JSON.parse(maybeJson);
      } catch {
      }
    }

    if (!error?.response?.data) {
      throw new ApiError({
        status: 500,
        path: error?.config?.url || '',
        method: error?.config?.method || '',
        id: 'GENERIC',
        message: this.intl!.formatMessage({
          defaultMessage: 'Neznámá chyba',
          description: 'Text chyby',
        }),
      });
    }

    throw error?.response?.data;
  };

  /**
   * post
   * @param url
   * @param data
   * @param config
   */
  private post = <Req, Res>(url: string, data?: Req, config?: AxiosRequestConfig) =>
    axios.post<Res>(url, data, config).catch(this.logError);
}

export default Api;
